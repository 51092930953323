import revive_payload_client_lzQfihwhnE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hixqEVLhqr from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_W0rbLqxYMA from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wgf5P6a6AL from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.0.4_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import check_outdated_build_client_FCla2jel0V from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_VdmKnTr2SR from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_EFY7dzZZBd from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_s9yKKVB39Y from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_dqmmP0j1Y3 from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_Hrh3J4555A from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import plugin_client_5Y7cfbf6lS from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_W2YR3o3EYG from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_zgqokoqhoy5sfbafuzhmvjx3hq/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QiY79SFQiR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Cm4hTmS0i9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_iKjgoQpOfn from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_ojj4ibawkdzewlx4bfsymu3kgm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/apps/website/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_kWEPRYfaoL from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_lzQfihwhnE,
  unhead_hixqEVLhqr,
  router_W0rbLqxYMA,
  _0_siteConfig_wgf5P6a6AL,
  check_outdated_build_client_FCla2jel0V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VdmKnTr2SR,
  siteConfig_EFY7dzZZBd,
  inferSeoMetaPlugin_s9yKKVB39Y,
  titles_dqmmP0j1Y3,
  defaultsWaitI18n_Hrh3J4555A,
  plugin_client_5Y7cfbf6lS,
  plugin_W2YR3o3EYG,
  switch_locale_path_ssr_QiY79SFQiR,
  i18n_Cm4hTmS0i9,
  chunk_reload_client_iKjgoQpOfn,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_kWEPRYfaoL
]